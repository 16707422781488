import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { decoratedFetch } from '../req_utils'; // Assuming this utility is defined in your project

export default function BranchSelect({ onBranchChange, dark = false, include_company = false }) { // default value for 'dark' and 'include_company'
    const employee = useSelector((state) => state.employee);
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState('');

    useEffect(() => {
        // Make sure employee.company is defined before fetching branches
        if (employee?.company?.id) {
            // Fetch branches data
            decoratedFetch(`/look_up_branches_by_company/${employee.company.id}`)
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Error fetching branches data");
                    }
                    return response.json();
                })
                .then((data) => {
                    setBranches(data);
                })
                .catch((error) => console.error("Error looking up branches: ", error));
        }
    }, [employee?.company?.id]); // Optional chaining to prevent accessing undefined properties

    const handleBranchChange = (event) => {
        const selectedBranch = event.target.value;
        setBranch(selectedBranch);
        onBranchChange(selectedBranch); // Notify parent about branch change
    };

    return (
        <FormControl
            fullWidth
            margin="normal"
            sx={{
                margin: '0px',
                maxWidth: '300px',
                color: dark ? 'white' : 'inherit', // Text color based on 'dark' prop
                '& .MuiInputLabel-root': {
                    color: dark ? 'white' : 'inherit', // Label color based on 'dark' prop
                },
                '& .MuiOutlinedInput-root': {
                    color: dark ? 'white' : 'inherit', // Text color for the select input
                    '& fieldset': {
                        borderColor: dark ? 'white' : 'inherit', // Border color based on 'dark' prop
                    },
                    '&:hover fieldset': {
                        borderColor: dark ? 'white' : 'inherit', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: dark ? 'white' : 'inherit', // Border color when focused
                    },
                },
                '& .MuiSvgIcon-root': {
                    color: dark ? 'white' : 'inherit', // Dropdown arrow color based on 'dark' prop
                },
            }}
        >
            <InputLabel>Branch</InputLabel>
            <Select
                value={branch}
                onChange={handleBranchChange}
                label="Branch"
                fullWidth
            >
                {/* Conditionally include the company MenuItem if include_company is true and employee.company exists */}
                {include_company && employee?.company && (
                    <MenuItem key={employee.company.id} value={employee.company.id}>
                        {employee.company.name}
                    </MenuItem>
                )}
                {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
