import React, { useState, useEffect } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BranchSelect from "../BranchSelect";

export function CreateProjectModal({ isOpen, handleClose, content }) {
  const navigate = useNavigate();
  const [account_id, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [project, setProjectName] = useState("");
  const [addressLn1, setAddressLn1] = useState("");
  const [addressLn2, setAddressLn2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [branch, setBranch] = useState("")

  const handleBranchChange = (selectedBranch) => {
    setBranch(selectedBranch);
  };

  const handleAccountChange = (event) => setAccount(event.target.value);
  const handleAccountNameChange = (event) => setAccountName(event.target.value);
  const handleProjectChange = (event) => setProjectName(event.target.value);
  const handleAddressLn1Change = (event) => setAddressLn1(event.target.value);
  const handleAddressLn2Change = (event) => setAddressLn2(event.target.value);
  const handleCityChange = (event) => setCity(event.target.value);
  const handleStateChange = (event) => setState(event.target.value);
  const handleZipChange = (event) => setZip(event.target.value);
  const employee = useSelector((state) => state.employee);
  
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    
    const branchOrCompanyId = employee.branch ? employee.branch.id : employee.company.id;

    // Build the appropriate URL
    const list_accounts_url = employee.branch
      ? `/list_accounts?branch_id=${branchOrCompanyId}`
      : `/list_accounts?company_id=${branchOrCompanyId}`;

    decoratedFetch(list_accounts_url)
      .then((response) => response.json())
      .then((data) => {
        // Check if 'data' is an array before updating state
        if (Array.isArray(data)) {
          setAccounts(data);
        } else {
          console.error("Expected an array but got:", data);
          setAccounts([]); // Set to an empty array or handle as appropriate
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setAccounts([]); // Set to an empty array in case of error
      });
  }, [isOpen]);

  const handleSend = () => {
    setLoading(true); // Start loading

    // Define the API suffix
    const url_suffix = "/create_project";
    console.log(branch);

    let payload;
    if (!branch){
      setSeverity("error");
        setAlertMessage(
          "Branch not selected",
        );
        setLoading(false);
        return;
    }
    if (!account_id) {
      // Check for required account details
      if (
        !accountName ||
        !addressLn1 ||
        !city ||
        !state
      ) {
        setSeverity("error");
        setAlertMessage(
          "All account fields must be filled: Name, Sales Rep, Address Line 1, City, State, and ZIP",
        );
        setLoading(false);
        return; 
      }

      // If account_id is empty, include account details
      payload = {
        name: project,
        branch_id: branch,
        company_id: employee.company.id,
        account: {
          name: accountName,
          address: {
            line_1: addressLn1,
            line_2: addressLn2 || "", // Optional, include even if empty
            city: city,
            state: state,
            zip: zip,
          },
        },
    };
    } else {
      payload = {
        account_id: account_id,
        name: project,
        branch_id: branch,
        company_id: employee.company.id,
      };
    }
    // Make the POST request
    decoratedFetch(url_suffix, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false); // Stop loading
        navigate(`/project/${data.project_id}`);
      })
      .catch((error) => {
        console.error("Error:", error); //Log the error
        setSeverity("error"); //Set alert type
        setLoading(false); // Stop loading in case of error
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(""); // Clear success message
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={() => {
        handleClose();
        setAlertMessage(""); // Also clear success message when closing
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Typography variant="h5" id="transition-modal-title">
              Create Project
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Project Name"
                  type="project name"
                  fullWidth
                  variant="outlined"
                  value={project}
                  onChange={handleProjectChange}
                />
              </Grid>
              <Grid item xs={6}>
                <BranchSelect onBranchChange={handleBranchChange}/>
              </Grid>
            </Grid>
            <Typography variant="h6">Account Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{
                    minWidth: 120,
                    width: "100%",
                  }}
                >
                  <InputLabel id="account-select-label">Account</InputLabel>
                  <Select
                    labelId="account-select-label"
                    id="account-select"
                    value={account_id}
                    onChange={handleAccountChange}
                    label="Account"
                    fullWidth
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Create New Account</em>
                    </MenuItem>
                    {accounts?.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    )) ?? []}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {account_id === "" && (
              <>
                <Typography variant="h6">Create New Account</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Account Name"
                      type="account name"
                      fullWidth
                      variant="outlined"
                      value={accountName}
                      onChange={handleAccountNameChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Address Line 1"
                      type="address line 1"
                      fullWidth
                      variant="outlined"
                      value={addressLn1}
                      onChange={handleAddressLn1Change}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Address Line 2"
                      type="address line 2"
                      fullWidth
                      variant="outlined"
                      value={addressLn2}
                      onChange={handleAddressLn2Change}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="City"
                      type="city"
                      fullWidth
                      variant="outlined"
                      value={city}
                      onChange={handleCityChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="State"
                      type="state"
                      fullWidth
                      variant="outlined"
                      value={state}
                      inputProps={{ maxLength: 2 }}
                      onChange={handleStateChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Zip Code"
                      type="zip"
                      fullWidth
                      variant="outlined"
                      value={zip}
                      inputProps={{ maxLength: 5 }}
                      onChange={handleZipChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                paddingTop: 10,
              }}
            >
              {loading ? (
                <CircularProgress
                  size="25px"
                  sx={{
                    textAlign: "center",
                    mt: "6px",
                    mb: "5.5px",
                    mr: "20px",
                    ml: "auto",
                  }}
                />
              ) : (
                <>
                  <Button
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    onClick={handleClose}
                    disabled={alertMessage}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={alertMessage}
                  >
                    Create
                  </Button>
                </>
              )}
            </div>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
