import React, { useState, useEffect, useRef } from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbar,
  GridLogicOperator,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { decoratedFetch } from "../req_utils";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const ProjectsTable = ({ employee_first_name, employee_last_name }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const apiRef = useRef(null);
  const initialFilters = [];
  if (employee_first_name && employee_last_name) {
    initialFilters.push(
      {
        id: 4,
        field: "assignee",
        operator: "contains",
        value: `${employee_first_name} ${employee_last_name}`,
      },
      { id: 1, field: "status", operator: "!=", value: "Archive" },
    );
  } else {
    initialFilters.push({
      id: 1,
      field: "status",
      operator: "!=",
      value: "Archive",
    });
  }
  const [filterModel, setFilterModel] = useState({
    items: initialFilters,
    logicOperator: GridLogicOperator.And,
  });

  const customStringOperators = () => {
    const defaultStringOperators = getGridStringOperators();
    // Adding a custom operator for "Not Equals"
    const notEqualsOperator = {
      label: "not equals",
      value: "!=",
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          return params !== filterItem.value;
        };
      },
      InputComponent: defaultStringOperators.find(
        (op) => op.value === "contains",
      ).InputComponent,
    };

    // Return the array of operators with the custom operator added
    return [...defaultStringOperators, notEqualsOperator];
  };

  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      width: 250,
      editable: false,
    },
    { field: "custom_id", headerName: "Project ID", width: 140 },
    {
      field: "status",
      headerName: "Status",
      width: 240,
      editable: false,
      filterOperators: customStringOperators(),
    },
    { field: "assignee", headerName: "Assignee", width: 250 },
    { field: "sales_person", headerName: "Sales Person", width: 250 },
    { field: "pm", headerName: "PM", width: 250 },
  ];

  useEffect(() => {
    setFilterModel(filterModel);
    setLoadingProjects(true);
    decoratedFetch("/list_projects")
      .then((response) => response.json())
      .then((data) => {
        const treeData = data.map((project) => ({
          id: project.id,
          projectName: project?.name,
          sales_person: project.sales_employee
            ? `${project.sales_employee.first_name} ${project.sales_employee.last_name}`
            : "",
          status: project.status.name,
          assignee: project.assignee
            ? `${project.assignee.first_name} ${project.assignee.last_name}`
            : "",
          pm: project.pm_employee
            ? `${project.pm_employee.first_name} ${project.pm_employee.last_name}`
            : "",
          treeDataPath: [project.account.name, project.custom_id], // Construct the path
        }));
  
        setProjects(treeData);
        setLoadingProjects(false);
      })
      .catch((error) => {
        setLoadingProjects(false);
        console.error(`Error listing projects: ${error}`);
      });
  }, []);

  const handleRowClick = (params, event) => {
    // Check if the row is a group row (has children) by checking if treeDataPath is present and has multiple parts
    if (params.row.treeDataPath && params.row.treeDataPath.length < 1) {
      const rowNode = apiRef.current.getRowNode(params.id);
      apiRef.current.setRowChildrenExpansion(
        params.id,
        !rowNode.childrenExpanded,
      );
      // Allow default behavior for expansion/collapse of group rows
      return;
    } else if (!params.row.treeDataPath) {
      const rowNode = apiRef.current.getRowNode(params.id);
      apiRef.current.setRowChildrenExpansion(
        params.id,
        !rowNode.childrenExpanded,
      );
      // This is the top-level group which might not have a treeDataPath
      return; // Just return to allow default behavior without navigation
    } else {
      // Prevent the default behavior to avoid any unwanted navigation or actions
      event.stopPropagation();
      // Navigate if it's a regular row
      navigate(`/project/${params.id}`);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGridPro
        rows={projects}
        slots={{ toolbar: GridToolbar }}
        columns={columns}
        initialState={{
          filter: {
            filterModel: {
              items: initialFilters,
              logicOperator: GridLogicOperator.And,
            },
          },
        }}
        apiRef={apiRef}
        getTreeDataPath={(row) => row.treeDataPath}
        groupingMode="treeData"
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        pageSize={5}
        onRowClick={(params, event) => handleRowClick(params, event)}
        localeText={{ noRowsLabel: "No projects" }}
        treeData
        setRowChildren
        loading={loadingProjects}
        groupingColDef={{
          headerName: "Account",
          width: 400,
        }}
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
            {
              outline: "none",
            },
        }}
      />
    </div>
  );
};

export default ProjectsTable;
