import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import roles from "../../data/roles";
import BranchSelect from "../BranchSelect";
import { useSelector } from 'react-redux';

export function InviteUserModal({
  isOpen,
  handleClose,
  setSnackbarOpen,
  setSnackbarMessage,
  handleUserInvite,
}) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState(""); // Store selected branch
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const employee = useSelector((state) => state.employee); 

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleRoleChange = (event) => setRole(event.target.value);

  const handleBranchChange = (selectedBranch) => {
    setBranch(selectedBranch);
  };

  const handleSend = () => {
    setLoading(true);

    const url_suffix = "/create_employee";
    let payload = {
      email: email,
      type: role,
    };

    // Check if the selected branch is the company or a specific branch
    if (branch === employee.company.id) {
      // Append only company_id if the selected branch is the company
      payload.company_id = employee.company.id;
    } else {
      // Append both company_id and branch_id if a specific branch is selected
      payload.company_id = employee.company.id;
      payload.branch_id = branch;
    }

    decoratedFetch(url_suffix, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw new Error("Error inviting user");
      })
      .then((data) => {
        setLoading(false);
        handleClose();
        setSnackbarOpen(true);
        setSnackbarMessage("User invited");
        handleUserInvite();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Error inviting user");
      });
  };

  return (
    <Dialog
      disableScrollLock
      fullWidth
      open={isOpen}
      onClose={() => {
        handleClose();
        setAlertMessage("");
      }}
      sx={{ width: "500px", margin: "auto", maxWidth: "100%" }}
    >
      <DialogTitle sx={{ fontSize: "22px", paddingBottom: "0px", flexGrow: 1 }}>
        Invite User
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" marginTop={1}>
          {alertMessage && (
            <Grid item xs={12}>
              <Alert
                onClose={() => setAlertMessage("")}
                severity={severity}
                sx={{ width: "100%", borderRadius: 8 }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                value={role}
                onChange={handleRoleChange}
                label="Role"
                fullWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {/* BranchSelect component */}
            <BranchSelect onBranchChange={handleBranchChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ margin: "5px", marginLeft: "10px" }}>
        {loading ? (
          <CircularProgress size="25px" />
        ) : (
          <div>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSend} disabled={alertMessage}>
              Send
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
