import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from '@mui/material';
import { decoratedFetch } from '../../req_utils';

export function AddNewBranchDialog({ open, handleClose, addBranch, branches, company, setSnackbarOpen, setSnackbarMessage, }) {
    const [branchName, setBranchName] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [line_1, setAddressLn1] = useState("");
    const [line_2, setAddressLn2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [prefix, setPrefix] = useState("");

    const handleAddressLn1Change = (event) => setAddressLn1(event.target.value);
    const handleAddressLn2Change = (event) => setAddressLn2(event.target.value);
    const handleCityChange = (event) => setCity(event.target.value);
    const handleStateChange = (event) => setState(event.target.value);
    const handleZipChange = (event) => setZip(event.target.value);

    const handleKeyPress = (event) => {
        const regex = /^[a-zA-Z]+$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      };

    const handleAddBranch = () => {
        setLoading(true); // Start loading

        // Define the API suffix
        const url_suffix = `/create_branch`;

        let payload;
        if (!branchName) {
            setSeverity("error");
            setAlertMessage(
                "Enter branch name",
            );
            setLoading(false);
            return;
        }
        if (!city || !state || !line_1 || !zip || !prefix) {
            setSeverity("error");
            setAlertMessage(
                "Required fields not filled in. Addresses must have the following: Address Line 1, City, State, Zip",
            );
            setLoading(false);
            return;
        }
        
        payload = {
            name: branchName,
            prefix: prefix,
            company_id: company.id,
            address: {
                line_1: line_1,
                line_2: line_2,
                city: city,
                state: state,
                zip: zip,
            },
        };

        // Make the POST request
        decoratedFetch(url_suffix, {
            method: "POST",
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.status === 201) {
                    return response.json();
                }
                throw new Error("Error message generated");
            })
            .then((data) => {
                setLoading(false); // Stop loading
                handleClose();
                setSnackbarOpen(true);
                setSnackbarMessage("Branch created successfully");
            })
            .catch((error) => {
                console.error("Error:", error); //Log the error
                setSeverity("error"); //Set alert type
                setLoading(false); // Stop loading in case of error
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Branch</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            label="Branch Name"
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Prefix"
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value.toUpperCase())}
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 4 }}
                            onKeyPress={handleKeyPress}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Address Line 1"
                            fullWidth
                            variant="outlined"
                            value={line_1}
                            onChange={handleAddressLn1Change}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            type="city"
                            fullWidth
                            variant="outlined"
                            value={city}
                            onChange={handleCityChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Address Line 2"
                            type="address line 2"
                            fullWidth
                            variant="outlined"
                            value={line_2}
                            onChange={handleAddressLn2Change}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="State"
                            type="state"
                            fullWidth
                            variant="outlined"
                            value={state}
                            inputProps={{ maxLength: 2 }}
                            onChange={handleStateChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Zip Code"
                            type="zip"
                            fullWidth
                            variant="outlined"
                            value={zip}
                            inputProps={{ maxLength: 5 }}
                            onChange={handleZipChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAddBranch} variant="contained" color="primary">
                    Add Branch
                </Button>
            </DialogActions>
        </Dialog>
    );
}
