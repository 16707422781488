import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MaskedInput from "react-text-mask";
import PhoneNumberFormat from "../components/PhoneNumberFormat";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  Snackbar,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import {
  SM_HEADER_HEIGHT_INT,
  LG_HEADER_HEIGHT_INT,
  SUPPORT_EMAIL,
  GENESIS_LOGO_COLOR,
} from "../constants";
import { updateEmployee } from "../reducers";

const SettingsPage = () => {
  const theme = useTheme();
  const employee = useSelector((state) => state.employee);

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmedNewPassword: "",
  });

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [emptyOldPasswordError, setEmptyOldPasswordError] = useState(false);
  const [emptyNewPasswordError, setEmptyNewPasswordError] = useState(false);
  const [emptyConfirmedNewPasswordError, setEmptyConfirmedNewPasswordError] =
    useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [uncollapsedAlertMessage, setUncollapsedAlertMessage] = useState(true);
  const [changePasswordButtonDisabled, setChangePasswordButtonDisabled] =
    useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const dispatchState = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;

    // Remove non-numeric characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  useEffect(() => {
    if (employee) {
      setUserInfo({
        firstName: employee.first_name || "",
        lastName: employee.last_name || "",
        email: employee.email || "",
        phone: employee.cell_no || "",
      });
    }
  }, [employee]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (!e.target.oldPassword.value) {
      setEmptyOldPasswordError(true);
      return;
    }
    setEmptyOldPasswordError(false);
    if (!e.target.newPassword.value) {
      setEmptyNewPasswordError(true);
      return;
    }
    setEmptyNewPasswordError(false);
    if (!e.target.confirmedNewPassword.value) {
      setEmptyConfirmedNewPasswordError(true);
      return;
    }
    setEmptyConfirmedNewPasswordError(false);
    if (e.target.newPassword.value !== e.target.confirmedNewPassword.value) {
      setPasswordMismatchError(true);
      return;
    }
    setPasswordMismatchError(false);
    setChangePasswordButtonDisabled(true);

    decoratedFetch("/change_password", {
      method: "POST",
      body: JSON.stringify({
        old_password: e.target.oldPassword.value,
        new_password: e.target.newPassword.value,
      }),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            setAlertMessage("Incomplete change password form");
            break;
          case 401:
            setAlertMessage("Incorrect password");
            break;
          case 404:
            setAlertMessage("Employee not found. Try refreshing the page.");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setAlertMessage("Password changed");
        setAlertSeverity("success");
        setUncollapsedAlertMessage(true);
        setChangePasswordButtonDisabled(false);
      })
      .catch((error) => {
        if (error.message !== "Error message generated") {
          setAlertMessage(
            `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
          );
        }
        setAlertSeverity("error");
        setUncollapsedAlertMessage(true);
        setChangePasswordButtonDisabled(false);
      });
  };

  const handleSave = () => {
    const api = `/update_employee/${employee.id}`;
    const formattedPhoneNumber = formatPhoneNumber(userInfo.phone);
    decoratedFetch(api, {
      method: "PUT",
      body: JSON.stringify({
        cell_no: formattedPhoneNumber,
      }),
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsDirty(false);
          const updatedEmployee = {
            ...employee,
            cell_no: formattedPhoneNumber,
          };
          dispatchState(updateEmployee(updatedEmployee));
          setSnackbarOpen(true);
          setSnackbarMessage("User updated");
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        console.error(`Error updating user`);
      });
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          marginTop: "40px",
          width: {
            xs: "340px !important",
            sm: "auto",
          },
        }}
      >
        {alertMessage ? (
          <Box
            sx={{
              mx: "auto",
            }}
          >
            <Collapse in={uncollapsedAlertMessage}>
              <Alert
                variant="outlined"
                severity={alertSeverity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setUncollapsedAlertMessage(false);
                      setAlertMessage("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alertMessage}
              </Alert>
            </Collapse>
          </Box>
        ) : null}

        <Typography
          style={{ fontWeight: "bold" }}
          sx={{
            typography: "body1",
            textAlign: "center",
            component: "p",
            size: "small",
            [theme.breakpoints.up("sm")]: {
              typography: "h5",
              component: "h5",
              size: "medium",
            },
          }}
        >
          User Information
        </Typography>
        <form style={{ marginTop: "25px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                value={userInfo.firstName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, firstName: e.target.value })
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={userInfo.lastName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lastName: e.target.value })
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                value={userInfo.phone}
                onChange={(e) =>
                  setUserInfo(
                    { ...userInfo, phone: e.target.value },
                    setIsDirty(true),
                  )
                }
                InputProps={{ inputComponent: PhoneNumberFormat }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!isDirty}
            onClick={handleSave}
          >
            Save
          </Button>
        </form>
        <Typography
          style={{ fontWeight: "bold" }}
          sx={{
            typography: "body1",
            textAlign: "center",
            component: "p",
            size: "small",
            [theme.breakpoints.up("sm")]: {
              typography: "h5",
              component: "h5",
              size: "medium",
            },
            paddingTop: 5,
          }}
        >
          Password
        </Typography>
        <form onSubmit={handlePasswordSubmit} style={{ marginTop: "25px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="oldPassword"
                label="Old Password"
                type="password"
                name="oldPassword"
                autoComplete="old-password"
                value={formData.oldPassword}
                onChange={(e) =>
                  setFormData({ ...formData, oldPassword: e.target.value })
                }
                error={emptyOldPasswordError}
                helperText={
                  emptyOldPasswordError ? "Old password must be non-empty" : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="newPassword"
                label="New Password"
                type="password"
                name="newPassword"
                autoComplete="new-password"
                value={formData.newPassword}
                onChange={(e) =>
                  setFormData({ ...formData, newPassword: e.target.value })
                }
                error={emptyNewPasswordError}
                helperText={
                  emptyNewPasswordError ? "New password must be non-empty" : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="confirmedNewPassword"
                label="Confirm New Password"
                type="password"
                name="confirmedNewPassword"
                autoComplete="confirmed-new-password"
                value={formData.confirmedNewPassword}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    confirmedNewPassword: e.target.value,
                  })
                }
                error={emptyConfirmedNewPasswordError || passwordMismatchError}
                helperText={
                  emptyConfirmedNewPasswordError
                    ? "Confirmed new password must be non-empty"
                    : passwordMismatchError
                      ? "New passwords must match"
                      : ""
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={changePasswordButtonDisabled}
            sx={{ mt: 3, mb: 2 }}
          >
            Change Password
          </Button>
        </form>
      </Container>
    </>
  );
};

export default SettingsPage;
