import { decoratedFetch } from "./req_utils";

export const createAutogeneratedLog = (project_id, createdById, content) => {
  return decoratedFetch("/create_log_item", {
    method: "POST",
    body: JSON.stringify({
      project_id: project_id,
      content: content,
      created_by_employee_id: createdById,
      autogenerated: true,
    }),
  });
};

export const createProjectAssignmentLog = (project, createdById, content) => {
  return decoratedFetch("/create_log_item", {
    method: "POST",
    body: JSON.stringify({
      project_id: project?.id,
      created_by_employee_id: createdById,
      content: content,
      autogenerated: true,
    }),
  });
};