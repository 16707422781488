import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, Button, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { SUPPORT_EMAIL } from "../constants";
import { decoratedFetch } from "../req_utils";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatchState = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [uncollapsedLoginErrorMessage, setUncollapsedLoginErrorMessage] =
    useState(true);
  const [signInButtonDisabled, setSignInButtonDisabled] = useState(false);

  const validEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    if (!password) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    setSignInButtonDisabled(true);

    decoratedFetch(
      "/log_in",
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      },
      /*no_refresh=*/ true,
    )
      .then(async (response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
          case 401:
          case 403:
            const data = await response.json();
            throw new Error(data.msg);
          case 500:
            throw new Error(
              `Internal server error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
          default:
            break;
        }
        throw new Error(
          `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
      })
      .then((data) => {
        decoratedFetch(
          "/look_up_employee",
          /*options=*/ {},
          /*no_refresh=*/ true,
        )
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                setLoginErrorMessage("User does not exist");
                break;
              case 401:
                setLoginErrorMessage("User unauthenticated");
                break;
              case 500:
                setLoginErrorMessage(
                  `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              default:
                setLoginErrorMessage(
                  `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
            }
            throw new Error("Error message generated");
          })
          .then((data) => {
            dispatchState({ type: "mark_as_authenticated", employee: data });
            navigate("/");
          })
          .catch((error) => {
            setSignInButtonDisabled(false);
            if (error.message !== "Error message generated") {
              setLoginErrorMessage(
                `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
              );
            }
            setUncollapsedLoginErrorMessage(true);
          });
      })
      .catch((error) => {
        setSignInButtonDisabled(false);
        setLoginErrorMessage(error.message);
        setUncollapsedLoginErrorMessage(true);
      });
  };

  useEffect(() => {
    const emailTextField = document.getElementById("email");
    const passwordTextField = document.getElementById("password");
    emailTextField.addEventListener("input", (e) => setEmail(e.target.value));
    passwordTextField.addEventListener("input", (e) =>
      setPassword(e.target.value),
    );
    emailTextField.addEventListener("change", (e) => setEmail(e.target.value));
    passwordTextField.addEventListener("change", (e) =>
      setPassword(e.target.value),
    );
    emailTextField.addEventListener("focus", (e) => setEmail(e.target.value));
    passwordTextField.addEventListener("focus", (e) =>
      setPassword(e.target.value),
    );
  }, [setEmail, setPassword]);

  return (
    <Box
      sx={{
        display: "grid",
        marginTop: "30px",
      }}
    >
      {loginErrorMessage ? (
        <Box
          sx={{
            width: "40%",
            mx: "auto",
          }}
        >
          <Collapse in={uncollapsedLoginErrorMessage}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setUncollapsedLoginErrorMessage(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginErrorMessage}
            </Alert>
          </Collapse>
        </Box>
      ) : null}
      <Box
        sx={{
          mx: "auto",
          marginTop: "60px",
          "& img": {
            width: {
              xs: "180px",
              sm: "295px",
            },
          },
        }}
      >
        <img src="/TradeOpsLogoDraft.png" alt="Trade Ops logo" />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mx: "auto",
          width: {
            xs: "300px",
            sm: "400px",
          },
          marginTop: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          error={emailError}
          helperText={emailError ? "Invalid email format" : ""}
          InputLabelProps={{ shrink: email?.length > 0 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          error={passwordError}
          helperText={passwordError ? "Password must be non-empty" : ""}
          InputLabelProps={{ shrink: password?.length > 0 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={signInButtonDisabled}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </Box>
      <Box
        sx={{
          mx: "auto",
          marginTop: "10px",
        }}
      >
				<Typography variant="body1">
					<Link to="/forgot-your-password">
						Forgot your password?
					</Link>
				</Typography>
      </Box>
    </Box>
  );
};

export default LoginPage;
