import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Snackbar, Box, Alert } from '@mui/material';
import { useSelector } from "react-redux";
import { AddNewBranchDialog } from './dialogs/AddNewBranchDialog';
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from '../constants';

// Columns for the DataGridPro
const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'type', headerName: 'Type', width: 150 },
];

// Function to get the tree data path for the hierarchy
const getTreeDataPath = (row) => row.hierarchy;

export default function CompanyHierarchy() {
  const employee = useSelector((state) => state.employee);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [company, setCompany] = useState(null);
  const [branches, setBranches] = useState([]);
  const [rows, setRows] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
    setSeverity("");
  };

  const handleAddBranch = (newBranch) => {
    const updatedBranches = [...branches, newBranch]; // Add the new branch to the list
    setBranches(updatedBranches);
    setRows(constructTreeData(company, updatedBranches)); // Update rows with new branch
  };

  // Function to construct tree data
  const constructTreeData = (company, branches) => {
    if (!company || !company.id || !company.name) {
      console.error("Company object is missing required properties.");
      return [];
    }

    const companyRow = {
      id: company.id, // Ensure the company row has a valid ID
      name: company.name,
      type: 'Company',
      hierarchy: [company.name],
    };

    const branchRows = branches.map((branch) => ({
      id: branch.id, // Ensure each branch row has a unique ID
      name: branch.name,
      type: 'Branch',
      hierarchy: [company.name, branch.name],
    }));

    return [companyRow, ...branchRows];
  };

  useEffect(() => {
    // Fetch company data
    decoratedFetch(`/look_up_company/${employee.company.id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error fetching company data");
        }
        return response.json();
      })
      .then((data) => {
        setCompany(data);
      })
      .catch((error) => console.error("Error looking up company: ", error));

    // Fetch branches data
    decoratedFetch(`/look_up_branches_by_company/${employee.company.id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error fetching branches data");
        }
        return response.json();
      })
      .then((data) => {
        setBranches(data);
      })
      .catch((error) => console.error("Error looking up branches: ", error));
  }, [employee.company.id]);

  useEffect(() => {
    if (company && branches.length > 0) {
      setRows(constructTreeData(company, branches));
    }
  }, [company, branches]);

  const groupingColDef = {
    headerName: 'Name',
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h4">{employee.company.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" onClick={handleDialogOpen} sx={{ mb: 2 }}>
            Add New Branch
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataGridPro
            treeData
            rows={rows}
            columns={columns}
            getTreeDataPath={getTreeDataPath}
            getRowId={(row) => row.id} // Ensure every row has a unique ID
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            defaultGroupingExpansionDepth={-1}
          />
        </Grid>
      </Grid>
      <AddNewBranchDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        addBranch={handleAddBranch}
        branches={branches}
        company={company}
        groupingColDef={groupingColDef}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  );
}
